import React from 'react';
import Layout from '../Layout';
import "./style.scss";
import pic from "../../images/urmila-devi.jpg";
import SEO from '../seo';

import shareicon from "../../images/volunteer-icon-3.png"
import { RWebShare } from 'react-web-share';



const BreakingTheCycle = ({ location }) => {
    return (
        <Layout>
            <SEO
                title="CORD Success Stories"
                description="Learn about how the Chinmaya Organisation for Rural Development aids women on their journey to self-reliance."
                keywords=""
                img={`https://${location.host}/link-preview.jpg`}
            />
            <div className='blog'>
                <h1>Breaking the cycle </h1>

                <div className="divider"></div>
                <img className='blog__img' src={pic}></img>
                <p>
                    Urmila’s alcoholic husband used to beat her every day and even tried to sell her to his drinking mates. That’s when Urmila ran away in the middle of the night with her four little children to her in-law’s home. However, her husband kept disturbing her and the children. Her situation was so deplorable that the villagers advised her to live with some other men, but Urmila refused to get into such a relationship.<br /><br />
                    That’s when CORD members intervened. Urmila started working as a clinic attendant, and later got trained as a Village Health Guide by CORD. With her diligence and hard work, she soon emerged as an excellent community-development worker. Further, she used her skills to change other people’s lives in the community too. She helped in strengthening the Mahila Mandal, SHGs, Adolescent Girls’ Groups, Farmers and Youth Groups, and Children’s Groups.<br /><br />
                    Urmila started by leading a team of women group facilitators at CORD as a supervisor, and later as Chief Community Development Worker. She also got elected as the ward representative in her Gram Panchayat, and started promoting local self-governance.
                    Recalling an incident about Urmila’s growth, Dr. Metre shares an incident where Urmila represented CORD to speak about the functioning of SHGs at a state-level seminar in Shimla, Himachal Pradesh. Dr. Metre was one of the panelists at the seminar, but was unable to attend, and instead asked Urmila to share the story of how SHGs had successfully helped in transforming lives in the region. Although the hall was crowded with senior officials of the state, heads of various government departments, and District Commissioners of the districts in the state, Urmila spoke non-stop for one-and-a-half hours sharing her field experience. She received a standing ovation at the end of her speech! Urmila Devi passed away on April 14, 2016 due to progressive neurological disease.<br /><br />
                    Urmila’s story is an inspiration for millions of women fighting for a dignified living.
                    <br /><br />
                    <RWebShare data={{
                        text: "",
                        url: `https://${location.host}/get-inspired/breaking-the-cycle`,
                        title: "Share this article"
                    }}>
                        <button style={{ background: "transparent", border: "none", color: "#EE896D" }} className='share-btn'>
                            <img width={22} height={22} src={shareicon} style={{ transform: "scaleX(-1)" }}></img>   SHARE
                        </button>
                    </RWebShare>

                </p>

                <a className='btn' target="_blank" href="https://cordindia.in/impact/#1">CORD Success Stories</a>

            </div>
        </Layout>
    );
}

export default BreakingTheCycle;
